import React from 'react'
//import { Link } from 'gatsby'

import logo from '../img/tonrik.svg'
//import facebook from '../img/social/facebook.svg'
//import instagram from '../img/social/instagram.svg'
//import twitter from '../img/social/twitter.svg'
//import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-white has-text-black-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Tonrik"
            style={{ width: '14em', height: '10em' }}
          />
        </div>
        <div className="content has-text-centered">
          <p>Tonrik AB<br />
          Hagagatan 46<br />
          113 47 Stockholm<br />
          <a href="Tel:0812104848">08-12 10 48 48</a><br />
          </p>
        </div>
        <div className="content has-text-left has-background-white has-text-black-ter">
          <div className="container has-background-white has-text-black-ter">
            <div className="columns">
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
